import Loader from 'common/components/Loader';
import Select from 'common/components/Select';
import { SettingsContext } from 'common/contexts/SettingsContext';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';

import Right from '../../assets/icons/Right';
import Button from '../../common/components/Button';

const UserLanguageSettingsForm = () => {
  const toast = useToast();
  const { t } = useTranslation('settings\\index');
  const settingsContext = useContext(SettingsContext);

  const availableLanguages = [
    { label: 'english', value: 'en' },
    { label: 'spanish', value: 'es' },
    { label: 'portuguese', value: 'pt' },
  ];

  const defaultLanguage =
    availableLanguages.find(
      (lng) => lng.value === localStorage.getItem('i18nextLng'),
    ) ?? availableLanguages[0];

  const initialValues = {
    language: defaultLanguage,
  };

  const handleSubmit = async (values) => {
    const settings = [{ name: 'language', value: values.language.value }];

    try {
      await settingsContext.updateCurrentUserSettings(settings);
    } catch (err) {
      const errorMessage =
        err?.status === 403
          ? t('toasts.forbidden')
          : t('toasts.something-wrong');
      toast.newToast('negative', errorMessage);
    }
  };

  return settingsContext.isLoadingUserSettings ? (
    <Loader />
  ) : (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form
        id='languages-settings-form'
        className='flex flex-col items-start w-full gap-4 mt-6'
      >
        <div className='w-1/3'>
          <Select
            name='language'
            label={t('language-label')}
            options={availableLanguages}
            translationPath='settings\\index'
            className='w-full'
          />
        </div>
        <div className='w-1/4'>
          <Button
            type='submit'
            variant='primary'
            form='languages-settings-form'
          >
            {t('save')}
            <Right size='16px' color='#FFFFFF' />
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default UserLanguageSettingsForm;
