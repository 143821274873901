import { apiSlice } from './api.slice';

export const applicationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffer: builder.query({
      query: ({ offerSlug }) => ({
        url: `/applications/offers/${offerSlug}`,
        method: 'GET',
      }),
    }),
    addSubmission: builder.mutation({
      query: ({ offerSlug, data }) => ({
        url: `/applications/offers/${offerSlug}/submissions/`,
        method: 'POST',
        body: data,
      }),
    }),
    getSubmissionByExternalSlug: builder.query({
      query: ({ externalSlug }) => ({
        url: `/applications/submissions/${externalSlug}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetOfferQuery,
  useAddSubmissionMutation,
  useGetSubmissionByExternalSlugQuery,
} = applicationsApiSlice;
