import React from 'react';

export const SubmissionDetailsSkeleton = () => (
  <div className='flex justify-center items-center min-h-screen bg-gray-100 px-4'>
    <div className='bg-white w-full max-w-2xl rounded-lg shadow-lg p-8'>
      <div className='text-center mb-4'>
        <div className='w-3/5 h-6 bg-gray-300 rounded mx-auto mb-2 animate-pulse'></div>
        <div className='w-2/5 h-5 bg-gray-300 rounded mx-auto animate-pulse'></div>
      </div>
      <div className='space-y-6'>
        <div className='border-t pt-6'>
          <div className='w-1/2 h-5 bg-gray-300 rounded mb-4 animate-pulse'></div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='w-full h-5 bg-gray-300 rounded animate-pulse'></div>
            <div className='w-full h-5 bg-gray-300 rounded animate-pulse'></div>
          </div>
        </div>

        <div className='border-t pt-6'>
          <div className='w-1/2 h-5 bg-gray-300 rounded mb-4 animate-pulse'></div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='w-full h-5 bg-gray-300 rounded animate-pulse'></div>
            <div className='w-full h-5 bg-gray-300 rounded animate-pulse'></div>
          </div>
        </div>

        <div className='border-t pt-6'>
          <div className='w-1/2 h-5 bg-gray-300 rounded mb-4 animate-pulse'></div>
          <div className='space-y-4'>
            <div>
              <div className='w-4/5 h-5 bg-gray-300 rounded mb-2 animate-pulse'></div>
              <div className='w-3/5 h-4 bg-gray-300 rounded animate-pulse'></div>
            </div>
            <div>
              <div className='w-4/5 h-5 bg-gray-300 rounded mb-2 animate-pulse'></div>
              <div className='w-3/5 h-4 bg-gray-300 rounded animate-pulse'></div>
            </div>
          </div>
        </div>

        <div className='border-t pt-6'>
          <div className='w-1/2 h-5 bg-gray-300 rounded mb-4 animate-pulse'></div>
          <div className='space-y-4'>
            <div>
              <div className='w-4/5 h-5 bg-gray-300 rounded mb-2 animate-pulse'></div>
              <div className='w-3/5 h-4 bg-gray-300 rounded animate-pulse'></div>
            </div>
          </div>
        </div>

        <div className='border-t pt-6'>
          <div className='w-1/2 h-5 bg-gray-300 rounded mb-4 animate-pulse'></div>
          <div className='space-y-4'>
            <div className='w-4/5 h-5 bg-gray-300 rounded animate-pulse'></div>
            <div className='w-4/5 h-5 bg-gray-300 rounded animate-pulse'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
