import { cn } from 'common/utils';
import { useField } from 'formik';
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const LabelledPhoneInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error;

  return (
    <div className='flex flex-col gap-2'>
      <label
        htmlFor={props.id || props.name}
        className={cn('text-xs font-semibold uppercase', {
          'text-weldon-blue': !hasError,
          'text-bittersweet-shimmer': hasError,
        })}
      >
        {label}
      </label>
      <div className='flex items-center relative'>
        <PhoneInput
          className={cn(
            'text-input text-dark-blue border rounded-md px-4 py-2.5 w-full',
            'disabled:border-soft-blue disabled:text-soft-blue disabled:bg-white disabled:cursor-not-allowed',
            {
              'border-dark-blue outline-dark-blue': !hasError,
              'border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
                hasError,
            },
          )}
          {...props}
          {...field}
          value={field.value}
          defaultCountry='CL'
          initialValueFormat='national'
          limitMaxLength={true}
          onChange={(value) => {
            const cleanedValue = value ? value.replace(/[^+\d]/g, '') : '';
            helpers.setValue(cleanedValue);
          }}
        />
      </div>
      {hasError ? (
        <div className='error text-bittersweet-shimmer text-xs font-semibold'>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default LabelledPhoneInput;
