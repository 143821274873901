import { cn } from 'common/utils';
import React from 'react';

export const Copy = ({ size = 24, color = '#8D91A0', onClick }) => {
  return (
    <svg
      className={cn({ 'cursor-pointer': onClick })}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 17 16'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M4.83301 4.14395V2.60425C4.83301 2.08648 5.25274 1.66675 5.77051 1.66675H13.8955C14.4133 1.66675 14.833 2.08648 14.833 2.60425V10.7292C14.833 11.247 14.4133 11.6667 13.8955 11.6667H12.3384'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.2295 4.33325H3.10449C2.58673 4.33325 2.16699 4.75299 2.16699 5.27075V13.3958C2.16699 13.9135 2.58673 14.3333 3.10449 14.3333H11.2295C11.7473 14.3333 12.167 13.9135 12.167 13.3958V5.27075C12.167 4.75299 11.7473 4.33325 11.2295 4.33325Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinejoin='round'
      />
    </svg>
  );
};
