import React from 'react';

export const ApplicationsNewSubmissionSkeleton = () => {
  return (
    <div className='p-8 bg-white w-full max-w-2xl rounded-lg shadow-lg space-y-6 animate-pulse'>
      <div className='space-y-2 text-center'>
        <div className='h-6 bg-gray-300 rounded-md mx-auto w-2/3'></div>
        <div className='h-4 bg-gray-300 rounded-md mx-auto w-1/3'></div>
        <div className='h-4 bg-gray-300 rounded-md mx-auto w-1/4 mt-2'></div>
      </div>

      <div className='space-y-6'>
        <div>
          <div className='h-6 bg-gray-300 rounded-md w-1/3 mb-4'></div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
          </div>
        </div>

        <div>
          <div className='h-6 bg-gray-300 rounded-md w-1/3 mb-4'></div>
          <div className='space-y-4'>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
          </div>
        </div>

        <div>
          <div className='h-6 bg-gray-300 rounded-md w-1/3 mb-4'></div>
          <div className='space-y-4'>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
          </div>
          <div className='h-8 bg-gray-300 rounded-md w-24 mt-4'></div>
        </div>

        <div>
          <div className='h-6 bg-gray-300 rounded-md w-1/3 mb-4'></div>
          <div className='space-y-4'>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
          </div>
          <div className='h-8 bg-gray-300 rounded-md w-24 mt-4'></div>
        </div>

        <div>
          <div className='h-6 bg-gray-300 rounded-md w-1/3 mb-4'></div>
          <div className='space-y-4'>
            <div className='h-10 bg-gray-300 rounded-md'></div>
            <div className='h-10 bg-gray-300 rounded-md'></div>
          </div>
        </div>
      </div>

      <div className='flex justify-center mt-6'>
        <div className='h-10 bg-gray-300 rounded-md w-32'></div>
      </div>
    </div>
  );
};
