import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { logOut } from './auth.slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  timeout: 10000,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken;
    const currentCompany = getState().auth.currentCompany;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    if (currentCompany) {
      headers.set('X-Company-Id', `${currentCompany.id}`);
    }

    return headers;
  },
});

const baseQueryWithReAuthAndRetry = retry(
  async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 400) {
      retry.fail(result.error);
    }

    if (result.error?.status === 401) {
      api.dispatch(logOut());
      api.dispatch(apiSlice.util.resetApiState());
      retry.fail(result.error);
    }

    if (result?.error?.status === 403) {
      retry.fail(result.error);
    }

    if (result?.error?.status === 404) {
      retry.fail(result.error);
    }

    return result;
  },
  { maxRetries: 3 },
);

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuthAndRetry,
  endpoints: (builder) => ({}),
  tagTypes: [
    'Offer',
    'Board',
    'Submission',
    'Setting',
    'CompanySetting',
    'Company',
    'Analytics',
    'Candidate',
    'Applications',
  ],
});
