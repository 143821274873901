import { cn } from 'common/utils';
import React from 'react';

const LabelledDisplay = ({ label, value, height, className }) => {
  return (
    <div className={cn('flex flex-col mb-2', className)}>
      <label
        className={cn('text-xs font-semibold uppercase', 'text-weldon-blue')}
      >
        {label}
      </label>
      <div className={cn('text-input text-dark-blue py-2 w-full', height)}>
        {value || ''}
      </div>
    </div>
  );
};

export default LabelledDisplay;
