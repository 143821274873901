import { cn } from 'common/utils';
import { useField } from 'formik';
import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const LabelledSocialInput = ({ label, platform, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;

  const getIcon = () => {
    switch (platform) {
      case 'linkedin':
        return (
          <FaLinkedin className='absolute left-3 text-dark-blue' size={20} />
        );
      case 'instagram':
        return (
          <FaInstagram className='absolute left-3 text-dark-blue' size={20} />
        );
      case 'twitter':
        return (
          <FaTwitter className='absolute left-3 text-dark-blue' size={20} />
        );
      case 'facebook':
        return (
          <FaFacebook className='absolute left-3 text-dark-blue' size={20} />
        );
      default:
        return null;
    }
  };

  return (
    <div className='flex flex-col gap-2'>
      <label
        htmlFor={props.id || props.name}
        className={cn('text-xs font-semibold uppercase', {
          'text-weldon-blue': !hasError,
          'text-bittersweet-shimmer': hasError,
        })}
      >
        {label}
      </label>
      <div className='flex items-center relative'>
        {getIcon()}
        <input
          className={cn(
            'text-input text-dark-blue border rounded-md px-10 py-2.5 w-full',
            'disabled:border-soft-blue disabled:text-soft-blue disabled:bg-white disabled:cursor-not-allowed',
            {
              'border-dark-blue outline-dark-blue': !hasError,
              'border-bittersweet-shimmer outline-bittersweet-shimmer placeholder-bittersweet-shimmer':
                hasError,
            },
          )}
          {...props}
          {...field}
        />
      </div>
      {hasError ? (
        <div className='error text-bittersweet-shimmer text-xs font-semibold'>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default LabelledSocialInput;
