import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileAdd from '../../../../assets/icons/FileAdd';
import { useToast } from 'common/hooks/useToast';

const CVModal = ({ onFileUpload }) => {
  const { t } = useTranslation('applications\\index');
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const handleFileValidation = (uploadedFile) => {
    if (uploadedFile?.type === 'application/pdf') {
      setFile(uploadedFile);
      onFileUpload(uploadedFile);
      toast.newToast('positive', t('form.modal.uploadSuccess'));
    } else {
      toast.newToast('negative', t('form.modal.fileTypeError'));
    }
  };

  return (
    <div
      className={`border-2 rounded-lg p-4 flex flex-col items-center justify-center transition ${
        dragging ? 'border-vibrant-orange' : 'border-gray-300'
      }`}
      style={{ maxWidth: '100%', height: '120px' }}
      onDragOver={(e) => {
        e.preventDefault();
        setDragging(true);
      }}
      onDragLeave={() => setDragging(false)}
      onDrop={(e) => {
        e.preventDefault();
        setDragging(false);
        handleFileValidation(e.dataTransfer.files[0]);
      }}
    >
      <FileAdd size="32px" color="#084D6E" />
      <p className="mt-2 text-gray-600 text-sm">
        <label
          htmlFor="pdf-upload"
          className="mt-2 text-vibrant-orange cursor-pointer underline text-sm mr-1"
        >
          {t('form.modal.selectFile')}
        </label>
        <span className='text-gray-400'>{t('form.modal.orDropHere')}</span>
      </p>
      <input
        type="file"
        accept="application/pdf"
        className="hidden"
        id="pdf-upload"
        onChange={(e) => handleFileValidation(e.target.files[0])}
      />

      {file && (
        <p className="mt-1 text-xs text-gray-800">
          {t('form.modal.uploadedFile')} <strong>{file.name}</strong>
        </p>
      )}
    </div>
  );
};

export default CVModal;
