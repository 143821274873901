import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Drawer from 'common/components/Drawer';
import LabelledInput from 'common/components/LabelledInput';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { selectCurrentCompany } from 'common/slices/auth.slice';
import {
  useGetCurrentCompanySettingsQuery,
  useUpdateCurrentCompanySettingsMutation,
} from 'common/slices/companyApi.slice';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Gorilla from '../../assets/icons/Gorilla';
import Left from '../../assets/icons/Left';
import Workable from '../../assets/icons/Workable';
import { ShowFeature } from '../../split';

const IntegrationsSettingsForm = () => {
  const { t } = useTranslation('settings\\index');
  const toast = useToast();

  const currentCompany = useSelector(selectCurrentCompany);

  const { data: companySettings, isLoading: isLoadingCompanySettings } =
    useGetCurrentCompanySettingsQuery({
      companyId: currentCompany.id,
    });

  const [
    updateCurrentCompanySettings,
    { isLoading: isUpdatingCompanySettings },
  ] = useUpdateCurrentCompanySettingsMutation();

  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const workableSetting = companySettings?.find(
    (setting) => setting.name === 'workable_api_key',
  );
  const subdomainSetting = companySettings?.find(
    (setting) => setting.name === 'workable_api_subdomain',
  );

  const initialValues = {
    workable_api_key: workableSetting?.value || '',
    workable_api_subdomain: subdomainSetting?.value || '',
  };

  const integrations = [
    {
      name: 'Workable',
    },
  ];

  const handleCardClick = (integration) => {
    setSelectedIntegration(integration);
  };

  const handleSave = async (values) => {
    const settings = Object.entries(values).map(([key, value]) => ({
      name: key,
      value,
    }));

    try {
      await updateCurrentCompanySettings({
        companyId: currentCompany.id,
        data: settings,
      }).unwrap();

      toast.newToast('positive', t('toasts.success'));
      setSelectedIntegration(null);
    } catch (err) {
      toast.newToast('negative', t('toasts.something-wrong'));
    }
  };

  return (
    <div className='relative w-full'>
      {isLoadingCompanySettings ? (
        <Loader />
      ) : (
        <>
          <div className='flex flex-wrap gap-6 mt-6'>
            {integrations.map((integration, index) => (
              <div
                key={index}
                className='w-[300px] p-4 border border-gray-300 rounded-lg shadow-sm cursor-pointer hover:shadow-md'
                onClick={() => handleCardClick(integration)}
              >
                <div className='flex justify-between items-center mb-2'>
                  <div className='font-semibold text-raisin-black flex'>
                    <Workable />
                    <i className='fas fa-cube mr-2'></i> {integration.name}
                  </div>
                  <Right size='16px' color='#6B7280' />
                </div>
                <div className='text-sm text-gray-500 mb-2'>
                  {integration.description}
                </div>
              </div>
            ))}
          </div>
          <Drawer
            isOpen={!!selectedIntegration}
            setIsOpen={() => setSelectedIntegration(null)}
            side='right'
            size='medium'
            outsideClickable
          >
            {selectedIntegration && (
              <div className='flex flex-col h-full p-6 bg-white'>
                <div className='mb-4'>
                  <Button
                    type='button'
                    variant='secondary'
                    onClick={() => setSelectedIntegration(null)}
                    size='16'
                    className='flex items-center gap-2 text-sm px-4 py-2 bg-vibrant-orange text-white hover:bg-orange-500 rounded-md'
                  >
                    <Left size='16px' color='#ffffff' />
                  </Button>
                </div>

                <div className='flex justify-between items-center mb-6'>
                  <div className='flex items-center gap-2'>
                    <Gorilla size={35} />
                    <h2 className='font-semibold text-xl'>
                      {selectedIntegration.name}
                    </h2>
                  </div>
                  <div className='flex items-center gap-4'>
                    <ShowFeature featureFlag='SHOW_SETTINGS_EXPERIMENTAL'>
                      <Button
                        type='button'
                        variant='secondary'
                        size='20'
                        className='flex items-center gap-2 text-sm px-4 py-2 bg-vibrant-orange text-white hover:bg-orange-500 rounded-md'
                      >
                        {t('reconnect')}
                      </Button>
                    </ShowFeature>
                  </div>
                </div>

                <div className='border rounded-lg shadow p-6 bg-white'>
                  <h3 className='text-lg font-semibold text-gray-800 mb-4'>
                    {t('connect-to')} {selectedIntegration.name}
                  </h3>

                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSave}
                  >
                    {({ getFieldProps }) => (
                      <Form>
                        <div className='mb-4'>
                          <LabelledInput
                            name='workable_api_key'
                            label={t(
                              `Clave de la API de ${selectedIntegration.name}`,
                            )}
                            type='text'
                            placeholder={t('Insert API Key')}
                            {...getFieldProps('workable_api_key')}
                          />
                        </div>

                        <div className='mb-6'>
                          <LabelledInput
                            name='workable_api_subdomain'
                            label={t(
                              `Subdominio de la API de ${selectedIntegration.name}`,
                            )}
                            type='text'
                            placeholder={t('Insert API Subdomain')}
                            {...getFieldProps('workable_api_subdomain')}
                          />
                        </div>

                        <Button
                          type='submit'
                          variant='primary'
                          disabled={isUpdatingCompanySettings}
                        >
                          {isUpdatingCompanySettings ? (
                            <Loader size='small' />
                          ) : (
                            t('save')
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </Drawer>
        </>
      )}
    </div>
  );
};

export default IntegrationsSettingsForm;
