import Add from 'assets/icons/Add';
import Check from 'assets/icons/Check';
import RadioChecked from 'assets/icons/RadioChecked';
import RadioUnchecked from 'assets/icons/RadioUnchecked';
import Right from 'assets/icons/Right';
import Trash from 'assets/icons/Trash';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import LabelledPhoneInput from 'common/components/LabelledPhoneInput';
import { useToast } from 'common/hooks/useToast';
import {
  useAddSubmissionMutation,
  useGetOfferQuery,
} from 'common/slices/applicationsApi.slice';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ApplicationsNewSubmissionSkeleton } from './components/ApplicationsNewSubmissionSkeleton';
import CVModal from 'offers/details/components/modals/CVModal';

const EntrySection = ({
  title,
  entries,
  currentEntry,
  setCurrentEntry,
  saveEntry,
  removeEntry,
  fields,
  addButtonText,
}) => {
  const { t } = useTranslation('applications\\index');

  return (
    <div className='border-t pt-6 relative'>
      <div className='flex justify-between items-center'>
        <h2 className='text-lg uppercase text-gray-600 font-semibold mb-4'>
          {title}
        </h2>
        <Button
          type='button'
          variant='secondary'
          onClick={() =>
            setCurrentEntry(
              fields.reduce(
                (acc, { name, type }) => ({
                  ...acc,
                  [name]: type === 'checkbox' ? false : '',
                }),
                {},
              ),
            )
          }
        >
          {t(addButtonText)}
          <Add size='14px' />
        </Button>
      </div>
      {entries.map((entry, index) => (
        <div
          key={index}
          className='flex flex-col mb-4 border-b transition-all mt-2 bg-gray-100 hover:shadow-md rounded-lg'
        >
          <div className='grid grid-cols-1 gap-y-1 p-4 flex-grow'>
            {Object.entries(entry).map(([key, value]) => (
              <div key={key} className='flex flex-col'>
                <span className='text-md font-semibold text-gray-600'>
                  {t(`form.fieldNames.${key}`, key.replace(/_/g, ' '))}
                </span>
                <span className='text-sm text-gray-800 py-1'>
                  {value || '-'}
                </span>
              </div>
            ))}
            <div className='flex justify-end mt-auto'>
              <Trash
                size={20}
                className='cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full'
                onClick={() => removeEntry(index)}
              />
            </div>
          </div>
        </div>
      ))}
      {currentEntry && (
        <>
          {fields.map(({ name, placeholder, type }) => (
            <LabelledInput
              key={name}
              name={name}
              placeholder={t(placeholder)}
              type={type}
              value={currentEntry[name]}
              onChange={(e) =>
                setCurrentEntry((prev) => ({ ...prev, [name]: e.target.value }))
              }
            />
          ))}
          <div className='flex justify-end'>
            <Button
              type='button'
              variant='primary'
              className='mt-4 px-4 min-w-16 flex items-center gap-2'
              onClick={saveEntry}
            >
              {t('form.saveButton')} <Check size='16px' color='#ffffff' />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const SubmissionNew = () => {
  const { t } = useTranslation('applications\\index');
  const toast = useToast();
  const { offerSlug } = useParams();
  const navigate = useNavigate();
  const [createSubmission] = useAddSubmissionMutation();
  const {
    data: offer,
    isLoading,
    error: offerError,
  } = useGetOfferQuery({ offerSlug });

  const [educationEntries, setEducationEntries] = useState([]);
  const [currentEducation, setCurrentEducation] = useState(null);
  const [experienceEntries, setExperienceEntries] = useState([]);
  const [currentExperience, setCurrentExperience] = useState(null);

  useEffect(() => {
    if (!isLoading && (!offer || offerError)) {
      navigate('/error', { state: { message: t('form.error.offerNotFound') } });
    }
  }, [offer, offerError, isLoading, navigate]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    headline: '',
    summary: '',
    experienceYears: '',
    experience: '',
    questions: offer?.questions?.reduce((acc, question) => {
      acc[question.slug] = '';
      return acc;
    }, {}),
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t('form.responses.firstNameNotSpecified')),
    lastName: Yup.string().required(t('form.responses.lastNameNotSpecified')),
    email: Yup.string()
      .email(t('form.responses.invalidEmail'))
      .required(t('form.responses.emailNotSpecified')),
    phone: Yup.string().required(t('form.responses.phoneNotSpecified')),
    location: Yup.string().required(t('form.responses.addressNotSpecified')),
    questions: Yup.object().shape(
      offer?.questions?.reduce((acc, question) => {
        acc[question.slug] =
          question.data_type === 'boolean'
            ? Yup.string().nullable()
            : Yup.string().nullable();
        return acc;
      }, {}),
    ),
  });

  const handleSubmit = async (values) => {
    const submissionData = {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      email: values.email.trim(),
      phone: values.phone.trim(),
      location: values.location.trim(),
      headline: values.headline?.trim() || null,
      summary: values.summary?.trim() || null,
      experience_years: values.experienceYears || null,
      experience: values.experience || '',
      experience_entries: experienceEntries,
      education_entries: educationEntries,
      questions: values.questions
        ? offer.questions.map((question) => ({
          question: question.name,
          question_slug: question.slug,
          answer: values.questions[question.slug] || null,
        }))
        : [],
      tests: {},
      url_linkedin: values.url_linkedin || '',
      url_facebook: values.url_facebook || '',
      url_instagram: values.url_instagram || '',
      url_twitter: values.url_twitter || '',
    };
    const formData = new FormData();

    if (values.cvFile) {
      formData.append('submission_pdf_file', values.cvFile);
    } 

    formData.append('data', JSON.stringify(submissionData));

    try {
      const response = await createSubmission({
        offerSlug,
        data: formData,
      }).unwrap();

      toast.newToast('positive', t('form.success.submission'));

      const externalSlug = response.external_slug;

      navigate(`/applications/s/${externalSlug}`, {
        state: { submissionData: response },
      });
    } catch {
      toast.newToast('negative', t('form.error.submissionFailed'));
    }
  };

  return (
    <div className='flex justify-center py-8 items-center min-h-screen bg-gray-100 px-4'>
      {isLoading ? (
        <>
          <ApplicationsNewSubmissionSkeleton />
        </>
      ) : (
        <div className='bg-white w-full max-w-2xl py-16 rounded-lg shadow-lg p-8'>
          <div className='text-center mb-6'>
            <h1 className='text-2xl font-semibold text-gray-800'>
              {offer?.title || t('form.responses.industryNotSpecified')}
            </h1>
            <p className='text-sm text-gray-500'>
              {offer?.location || t('form.responses.addressNotSpecified')}
            </p>
            <p
              className='text-xs text-vibrant-orange bg-[#F16347]/20 rounded-md mx-auto px-2 py-1 mt-2 text-center'
              style={{ width: 'fit-content', height: '22px' }}
            >
              {offer?.work_type
                ? t(`form.work_type.${offer.work_type}`)
                : t('form.responses.workTypeNotSpecified')}
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form
                className='space-y-6'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                <div className='border-t pt-6'>
                  <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
                    {t('form.personalInformation')}
                  </h2>
                  <div className='grid grid-cols-1 md:grid-cols-2 md:gap-1'>
                    <LabelledInput
                      name='firstName'
                      placeholder={t('form.fieldNames.firstName')}
                      type='text'
                    />
                    <LabelledInput
                      name='lastName'
                      placeholder={t('form.fieldNames.lastName')}
                      type='text'
                    />
                  </div>
                  <LabelledInput
                    name='location'
                    placeholder={t('form.fieldNames.location')}
                    type='text'
                  />
                </div>

                <div className='border-t pt-6'>
                  <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
                    {t('form.contact')}
                  </h2>
                  <LabelledInput
                    name='email'
                    placeholder={t('form.fieldNames.email')}
                    type='email'
                  />
                  <LabelledPhoneInput
                    name='phone'
                    placeholder={t('form.fieldNames.phone')}
                    type='tel'
                  />
                </div>

                <div className='border-t pt-6 mb-8'>
                  <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
                    Curriculum
                  </h2>
                  <CVModal
                    onFileUpload={(uploadedFile) => {
                      setFieldValue('cvFile', uploadedFile);
                    }}
                  />
                </div>

                <EntrySection
                  title={t('form.education')}
                  entries={educationEntries}
                  currentEntry={currentEducation}
                  setCurrentEntry={setCurrentEducation}
                  saveEntry={() => {
                    setEducationEntries((prev) => [...prev, currentEducation]);
                    setCurrentEducation(null);
                  }}
                  removeEntry={(index) =>
                    setEducationEntries((prev) =>
                      prev.filter((_, i) => i !== index),
                    )
                  }
                  fields={[
                    {
                      name: 'degree',
                      placeholder: 'form.fieldNames.degree',
                      type: 'text',
                    },
                    {
                      name: 'school',
                      placeholder: 'form.fieldNames.school',
                      type: 'text',
                    },
                    {
                      name: 'field_of_study',
                      placeholder: 'form.fieldNames.fieldOfStudy',
                      type: 'text',
                    },
                    {
                      name: 'start_date',
                      placeholder: 'form.fieldNames.start_date',
                      type: 'date',
                    },
                    {
                      name: 'end_date',
                      placeholder: 'form.fieldNames.end_date',
                      type: 'date',
                    },
                  ]}
                  addButtonText='form.addButton'
                />

                <EntrySection
                  title={t('form.experience')}
                  entries={experienceEntries}
                  currentEntry={currentExperience}
                  setCurrentEntry={setCurrentExperience}
                  saveEntry={() => {
                    setExperienceEntries((prev) => [
                      ...prev,
                      currentExperience,
                    ]);
                    setCurrentExperience(null);
                  }}
                  removeEntry={(index) =>
                    setExperienceEntries((prev) =>
                      prev.filter((_, i) => i !== index),
                    )
                  }
                  fields={[
                    {
                      name: 'title',
                      placeholder: 'form.fieldNames.title',
                      type: 'text',
                    },
                    {
                      name: 'summary',
                      placeholder: 'form.fieldNames.summary',
                      type: 'text',
                    },
                    {
                      name: 'company',
                      placeholder: 'form.fieldNames.company',
                      type: 'text',
                    },
                    {
                      name: 'industry',
                      placeholder: 'form.fieldNames.industry',
                      type: 'text',
                    },
                    {
                      name: 'start_date',
                      placeholder: 'form.fieldNames.start_date',
                      type: 'date',
                    },
                    {
                      name: 'end_date',
                      placeholder: 'form.fieldNames.end_date',
                      type: 'date',
                    },
                  ]}
                  addButtonText='form.addButton'
                />

                {offer?.questions?.length > 0 && (
                  <div className='border-t pt-6'>
                    <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
                      {t('form.questions')}
                    </h2>
                    <div className='space-y-6'>
                      {offer.questions.map((question) => (
                        <div
                          key={question.slug}
                          className='flex flex-col gap-2'
                        >
                          <label
                            htmlFor={question.slug}
                            className='text-sm font-semibold text-gray-600'
                          >
                            {question.name}
                          </label>
                          {question.description && (
                            <p className='text-sm text-gray-500'>
                              {question.description}
                            </p>
                          )}
                          {question.data_type === 'boolean' ? (
                            <div className='flex flex-col gap-2 mt-2'>
                              <label className='flex items-center gap-2 cursor-pointer'>
                                <Field
                                  type='radio'
                                  id={`${question.slug}-yes`}
                                  name={`questions.${question.slug}`}
                                  value='true'
                                  className=''
                                />
                                {({ field }) =>
                                  field.checked ? (
                                    <RadioChecked size='24' color='#084D6E' />
                                  ) : (
                                    <RadioUnchecked size='24' color='#084D6E' />
                                  )
                                }
                                <span className='text-sm text-dark-blue'>
                                  {t('form.yes')}
                                </span>
                              </label>
                              <label className='flex items-center gap-2 cursor-pointer'>
                                <Field
                                  type='radio'
                                  id={`${question.slug}-no`}
                                  name={`questions.${question.slug}`}
                                  value='false'
                                  className=''
                                />
                                {({ field }) =>
                                  field.checked ? (
                                    <RadioChecked size='24' color='#084D6E' />
                                  ) : (
                                    <RadioUnchecked size='24' color='#084D6E' />
                                  )
                                }
                                <span className='text-sm text-dark-blue'>
                                  {t('form.no')}
                                </span>
                              </label>
                            </div>
                          ) : (
                            <LabelledInput
                              name={`questions.${question.slug}`}
                              placeholder={question.name}
                              type='text'
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className='sticky bottom-0 py-4 z-10 flex justify-end w-full'>
                  <Button
                    type='submit'
                    variant='primary'
                    className='px-6 py-2 opacity-100'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? t('form.loading') : t('form.submitButton')}
                    <Right size='16px' color='#FFFFFF' />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default SubmissionNew;
